import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import configs from '@/configs'
const BLOCK_SECOND: number = 60
let timeout: any

declare module 'vue/types/vue' {
  interface Vue {
    remainingSecond: number,
    secondForChangePhone: number,
    smsText: string,
    smsTextForChangePhone: string,
    sendSMSCode(mobile: string, type: number, isSecond: number, channel?: string): Promise<any>,
    sendV5SMSCode(mobile: string, type: number, isSecond: number): Promise<any>,
    validateOldPhoneSMSCode(isSecond: number): Promise<any>,
    startCountDown(): void,
    sendSMSCodeToSafePhone(): Promise<any>,
    clearCountDown(): void,
  }
}

@Component
export default class SMSMixin extends Vue {
  @Provide() public remainingSecond: number = 0
  @Provide() public secondForChangePhone: number = 0
  @Provide() public sent: boolean = false
  @Provide() public sentForChangePhone: boolean = false

  get smsText () {
    if (this.remainingSecond > 0) {
      return `${this.remainingSecond}s`
    }
    return this.sent ? '重新获取' : '获取验证码'
  }

  get smsTextForSafe () {
    if (this.remainingSecond > 0) {
      return `${this.remainingSecond}s`
    }
    return this.sent ? '重新获取' : '获取验证码'
  }

  get canSend () {
    return this.remainingSecond <= 0
  }

  public async sendSMSCode (mobile: string = '', type: number = 1, isSecond: number = 1, channel?: string) {
    if (isSecond === 0) {
      this.remainingSecond = 0
    }
    if (this.remainingSecond > 0) {
      return [undefined, undefined]
    }
    try {
      const res = await httpHelper.post({
        url: 'sms',
        // contentType: 'application/x-www-form-urlencoded',
        data: {
          phone: mobile,
          smsType: type,
          channel
        },
        headers: {
          Authorization: configs.basicAuthorization
        }
      })
      this.sent = true
      this.startCountDown()
      return [undefined, res]
    } catch (err) {
      return [err, undefined]
    }
  }

  public async sendV5SMSCode (mobile: string = '', type: number = 1, isSecond: number = 1) {
    if (isSecond === 0) {
      this.remainingSecond = 0
    }
    if (this.remainingSecond > 0) {
      return [undefined, undefined]
    }
    try {
      const res = await httpHelper.post({
        url: 'sms',
        data: {
          phone: mobile,
          smsType: type
        },
        type: 'apiv5'
        // headers: {
        //   Authorization: configs.basicAuthorization
        // }
      })
      this.sent = true
      this.startCountDown()
      return [undefined, res]
    } catch (err) {
      return [err, undefined]
    }
  }

  public async sendSMSCodeToSafePhone () {
    if (this.remainingSecond > 0 || timeout) {
      return [undefined, undefined]
    }
    try {
      const res = await httpHelper.get({
        url: 'account/validPhone/sms'
      })
      this.sent = true
      this.startCountDown()
      return [undefined, res]
    } catch (err) {
      return [err, undefined]
    }
  }

  public startCountDown () {
    const self = this
    this.remainingSecond = BLOCK_SECOND
    clearInterval(timeout)
    timeout = setInterval(() => {
      const newRemain = self.remainingSecond - 1
      if (newRemain < 0) {
        clearInterval(timeout)
        timeout = undefined
        return
      }
      self.remainingSecond = newRemain
    }, 1000)
  }

  // 以下是更换安全手机获取旧手机验证码专用

  public async validateOldPhoneSMSCode (isSecond: number = 1) {
    if (isSecond === 0) {
      this.remainingSecond = 0
    }
    if (this.remainingSecond > 0) {
      return [undefined, undefined]
    }
    try {
      const res = await httpHelper.post({
        url: 'IAccount/SendSMS',
        type: 'apiv5'
      })
      this.sentForChangePhone = true
      this.startCountDownForChangePhone()
      return [undefined, res]
    } catch (err) {
      return [err, undefined]
    }
  }

  get smsTextForChangePhone () {
    if (this.secondForChangePhone > 0) {
      return `${this.secondForChangePhone}s后重新获取`
    }
    return this.sentForChangePhone ? '重新获取' : '获取验证码'
  }

  get smsTextForNewPhone () {
    if (this.remainingSecond > 0) {
      return `${this.remainingSecond}s后重新获取`
    }
    return this.sent ? '重新获取' : '获取验证码'
  }

  public startCountDownForChangePhone () {
    const self = this
    this.secondForChangePhone = BLOCK_SECOND
    clearInterval(timeout)
    timeout = setInterval(() => {
      const newRemain = self.secondForChangePhone - 1
      if (newRemain < 0) {
        clearInterval(timeout)
        timeout = undefined
        return
      }
      self.secondForChangePhone = newRemain
    }, 1000)
  }
  public clearCountDown () {
    this.remainingSecond = 0
    this.sent = false
  }
}
